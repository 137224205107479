import {
  ILocation,
  IPage,
  IPagination,
  IPost,
  IPostPage,
  TagInterface
} from '@standardTypes';
import {Helmet} from 'react-helmet'
import React from 'react';

import { Layout } from '@components/layout';
import {
  BlogHero,
  CategoryPosts,
  SectionsWithBackround
} from '@components/organisms/standard';

interface IPageContext {
  pageContext: {
    page: IPage | IPostPage | IPost;
    posts: IPost[];
    tag: TagInterface;
    translationsUrls: Record<string, string>;
    pagination: IPagination;
  };
  location: ILocation;
}

const Tag: React.FC<IPageContext> = ({
  pageContext,
  location
}): JSX.Element => {
  const { page, posts, translationsUrls, pagination } = pageContext;
  const { skip, limit, currentPage } = pagination;
  const { pathname } = location;

  const currentPagePosts = posts.slice(skip, limit * currentPage);
  const backgroundTiles = currentPagePosts.length > 7 ? 4 : 3;

  const seoTite = page.name ? page.name : '';

  function Capitalize(str:string){
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return (
    <Layout {...page} translationUrls={translationsUrls}>
      <Helmet>
        <title>{Capitalize(seoTite)}</title>
      </Helmet>
      <BlogHero />
      <SectionsWithBackround numberOfBackgrounds={backgroundTiles}>
        <CategoryPosts
          posts={currentPagePosts}
          activeLink="tag"
          tagName={page.name}
          pagination={pagination}
          pathname={pathname}
        />
      </SectionsWithBackround>
    </Layout>
  );
};

export default Tag;
